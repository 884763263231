import React from 'react'
import { Route, Link } from 'react-router-dom'
import styled from 'styled-components'
import Page from './001/Page.js'
import Thumbnail from './001/shot-daily-ui-001-main.png'

const projects = [
  { 
    // thumbnail: Thumbnail,
    // thumbnail: './001/shot-daily-ui-001-main.png',
    title: 'Sign Up',
    brief: 'Design a sign up page, modal, form, app screen, etc.',
    codeLink: 'https://github.com/jimlears/jimlears-web/tree/master/src/scenes/DailyUI/001',
    designLink: '',
    discussionLink: '',
    theme: 'dark'
  },
  // 'Credit Card Checkout',
  // 'Landing Page (above the fold)',
  // 'Calculator',
  // 'App Icon',
  // 'User Profile',
  // 'Settings',
  // '404 page',
  // 'Music Player',
  // 'Social Share',
  // 'Flash Message (Error/Success)'
]

const DailyUI = ({ match }) => {
  const { id } = match.params
  return (
    <Layout>
      {
        !id &&
        <>
          <Nav>
            <h1>Daily UI</h1>
            <ul>
              {/* <li>
                <Link to="/projects/daily-ui/001">001 → Sign Up</Link>
                <div>Design a sign up page, modal, form, app screen, etc. </div>
              </li> */}
              {
                projects.map((project, ndx) => {
                  const ndxStr = ndx+1
                  return (
                    <li>
                      {/* <img width="200" src={project.thumbnail}></img> */}
                      <Link to="/projects/daily-ui/001">
                        <img width="200" src={require('./001/shot-daily-ui-001-main.png')} alt="thumbnail" />
                        {ndxStr.toString().padStart(3,'0')} → {project.title}
                        <div>{project.brief}</div>
                      </Link>
                      {/* <a href={project.codeLink}>{project.codeLink}</a> */}
                    </li>
                  )
                })
              }
              {/* <li>
                <Link to="/daily-ui/002">002 → (next up)</Link>
              </li> */}
            </ul>
          </Nav>
        </>
      }
      <Route path="/projects/daily-ui/001" component={Page} />
    </Layout>
  )
}

const Layout = styled.div`
  background-color: #407EFF;
  color: white;
  overflow-y: scroll;

  height: 100vh;
  width: 100vw;
  flex: 1;
  display: flex;
  flex-direction: column;

`

const Nav = styled.nav`
  margin: 32px;

  a, a:visited, a:active {
    color: white;
    text-decoration: none;
    font-variant-numeric: tabular-nums;
  }

  ul {
    padding: 0;
    margin: 0;

    > li {
      margin-bottom: 8px;
    }

    > li:last-child { 
      margin-bottom: 0 !important;
    }
  }
`


export default DailyUI
