import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Home } from 'scenes/Home'
import { DailyUI } from 'scenes/DailyUI'
import { EditorView } from 'scenes/EditSuggestions'

const App = () => {
  const About = () => <h2>About</h2>

  return (
    <Router>
      <Switch>      
        <Route exact path="/" component={Home} />
        <Route exact path="/projects/daily-ui" component={DailyUI} />
        <Route exact path="/projects/daily-ui/:id" component={DailyUI} />
        <Route exact path="/projects/edit-suggestions" component={EditorView} />
        <Route path="/about" component={About} />
      </Switch>
    </Router>
  )
}

export default App
