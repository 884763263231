import React, { useRef, useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import isRegExp from 'lodash/isRegExp'
import escapeRegExp from 'lodash/escapeRegExp'
import isString from 'lodash/isString'
import flatten from 'lodash/flatten'
import { Stats, stat } from 'fs';

/*
  ‘Close’ Proximity, ‘End’ Result, and More Redundant Words to Delete From Your Writing
  https://medium.com/s/story/close-proximity-end-result-and-more-redundant-words-to-delete-from-your-writing-3258be693a3d

  absolutely certain
  absolute certainty
  absolutely essential
*/

const propTypes = {
  // children: PropTypes.element.isRequired,
}

const defaultProps = {
}

const initialState = {
  element: null,
  pos: null,
  ngram: '',
  suggestion: '',
}

const defaultInnerText = { __html: `
  Google Docs is a word processor included as part of a free, web-based software office suite offered by Google within its Google Drive service. This service also includes Google Sheets and Google Slides, a spreadsheet and presentation program respectively. Google Docs is available as a web application, mobile app for Android, iOS, Windows, BlackBerry, and as a desktop application on Google's ChromeOS.
` }

const suggestions = [
  { ngram: 'google', suggestion: 'The Evil Corporation' },
  { ngram: 'free', suggestion: 'enslaved' },
]

function reducer(state, action) {
  switch (action.type) {
    case 'set_mark':
      var entry = suggestions.filter(entry => {
        return entry.ngram.toLowerCase() === action.payload.ngram.toLowerCase()
      })
      // this feels hacky
      const suggestion = entry[0].suggestion
      return { ...state, ...action.payload, suggestion: suggestion }
    case 'set_pop_pos':
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

const EditorView = () => {
  const content = useRef()
  const [ innerText, setInnerText ] = useState(defaultInnerText)
  const [ processed, setProcessed ] = useState(false)
  const [ showPop, setShowPop ] = useState(false)
  const [ state, dispatch ] = useReducer(reducer, initialState)

  // attach on click handlers to all <mark/> elements
  useEffect(() => {
    // hack!
    var spans = document.getElementsByTagName('mark')
    for (var i = 0; i < spans.length; i++) {
      spans[ i ].onclick = handleMarkClicked
    }
  },[processed])

  // find all matches
  const findMatches = (str, text) => {
    // build a regex
    let re = str
    if (!isRegExp(re)) {
      re = new RegExp('(' + escapeRegExp(re) + ')', 'gi');
    }

    // split into chunks
    const res = text.split(re)
    return res
  }

  // process matches
  const processMatches = (matches) => {
    const processed = matches.map((match, ndx) => {
      console.log(match)

      // odd = matches
      if (ndx % 2) {
        return `<mark data-value=${match}>${match}</mark>`
        // even = not a match
      } else {
        return `${match}`
      }
    })

    console.log(processed)

    let innerHTML = ''
    processed.forEach(item => {
      innerHTML += item
      console.log(item)
    })

    return innerHTML
  }

  //
  // event handlers
  //

  const handleFindClicked = (e) => {
    let re
    let matches
    let innerHTML

    // find all instances of suggested changes
    suggestions.forEach(suggestion => {
      re = suggestion.ngram
      matches = findMatches(re, content.current.innerHTML)
      innerHTML = processMatches(matches)
      content.current.innerHTML = innerHTML
    })

    setProcessed(true)
  }

  const handleResetClicked = () => {
    content.current.innerHTML = defaultInnerText.__html
    setProcessed(false)
  }

  const handleMarkClicked = (e) => {
    setShowPop(false)

    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    // console.log('handleMarkClicked')
    // console.log(e.target.offsetWidth)

    let pos = offset(e.target)
    pos.left = pos.left - (e.target.offsetWidth)
    console.log(pos)

    const payload = {
       element: e.target,
       ngram: e.target.innerText
    }

    dispatch({ type: 'set_mark', payload: payload })
    dispatch({ type: 'set_pop_pos', payload: { pos: pos } })

    setShowPop(true)
  }

  const handleIgnoreClicked = () => {
    const { element, ngram } = state

    // remove all
    const matches = content.current.querySelectorAll(`mark[data-value=${ngram}]`)
    console.log(matches)
    matches.forEach(match => {
      match.outerHTML = match.innerText
    })

    // // remove this instance of mark
    // if (element.nodeName === 'MARK') {
    //   console.log(element.innerText)
    //   element.outerHTML = element.innerText
    // }

    setShowPop(false)
  }

  const handleChangeClicked = () => {
    const { element, ngram } = state

    // change all
    const matches = content.current.querySelectorAll(`mark[data-value=${ngram}]`)
    console.log(matches)
    matches.forEach(match => {
      match.outerHTML = state.suggestion
    })

    // change this instance of mark
    // const { element } = state
    // if (element.nodeName === 'MARK') {
    //   console.log(element.innerText)
    //   element.outerHTML = state.suggestion
    // }

    setShowPop(false)
  }

  //

  const handleOnEvent = (e) => {
    console.log('handle')
  }

  return (
    <Layout>
      <Toolbar>
        <button onClick={handleResetClicked}>reset</button>
        <button onClick={handleFindClicked} disabled={processed}>find</button>
      </Toolbar>
      <Content>
        {
          // (true || showPop) &&
          <Pop
            display={showPop ? 'block' : 'none'}
            // display={'block'}
            top={state.pos ? state.pos.top : '200'}
            left={state.pos ? state.pos.left : '200'}
          >
            <div class="highlightMenu-arrowClip"><span class="highlightMenu-arrow"></span></div>
            <Toolbar>
              <button onClick={handleIgnoreClicked}>ignore</button>
              <i class="material-icons">arrow_right_alt</i>
              <button onClick={handleChangeClicked}>change</button>
            </Toolbar>
          </Pop>
        }
        <p
          ref={content}
          contentEditable
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          dangerouslySetInnerHTML={innerText}
          onPaste={handleOnEvent}
        >
        </p>
      </Content>
    </Layout>
  )
}

const Layout = styled.div`
  margin: 32px;

  input {
    font-size: 14px;
    line-height: 32px;
  }

  input, button, button:focus {
    height: 36px;
    background: none;
    outline: none;
    padding: 0 12px 0 12px;
    border-radius: 4px;
    border: 1px solid #333;
    vertical-align: top;
  }

  button, button:focus {
    border: 1px solid #333;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }

  button:active {
    color: white;
    background-color: #333
  }

  p {
    padding: 16px;
    font-size: 18px;
    line-height: 27px;
    height: 400px;
    overflow-y: auto;
    outline: none;
    border-radius: 4px;
    border: 1px dashed black;
  }

  p::selection {
    background-color: pink;
  }

  [contenteditable]:focus {
    // outline: 0px solid transparent;
    // outline: 1px solid pink;
    // outline: 1px solid black;
  }

  mark {
    border-radius: 2px;
    // padding: 0 4px 0 4px;
    cursor: pointer;
    // background-color: #FFAEEA;
    background-color: white;
    // background-image: linear-gradient(to bottom,rgba(12,242,150,.5),rgba(12,242,150,.5));
    background-image: linear-gradient(to bottom,rgba(255,0,188,.5),rgba(255,8,188,.5));
  }
`
const Toolbar = styled.div`
  >* {
    margin-right: 8px;
  }
  >:last-child {
    margin-right: 0;
  }
  > i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    font-size: 24px;
    // border: 1px dashed pink;
  }
`

const Content = styled.div`
  // position: relative;
`

const Pop = styled.div`
  @keyframes pop-upwards {
   0% {
    transform: matrix(.97,0,0,1,0,12);
    opacity: 0;
    }
    20% {
        transform: matrix(.99,0,0,1,0,2);
        opacity: .7;
    }
    40% {
        transform: matrix(1,0,0,1,0,-1);
        opacity: 1;
    }
    70% {
        transform: matrix(1,0,0,1,0,0);
        opacity: 1;
    }
    100% {
        transform: matrix(1,0,0,1,0,0);
        opacity: 1;
    }
  }


  display:  ${ props => props.display };
  top: ${ props => props.top - 48 }px;
  left: ${ props => props.left }px;

  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  // border: 1px solid black;

  position: absolute;
  background-color: white;
  padding: 8px;
  border-radius: 8px;

  >* {
    margin-right: 8px;
  }
  >:last-child {
    margin-right: 0;
  }

  z-index: 666;

  // overflow: hidden;
  transition: top 75ms ease-out,left 75ms ease-out;
  animation: pop-upwards 180ms forwards linear;


  & .highlightMenu-arrowClip {
    z-index: 555;
    position: absolute;
    bottom: -10px;
    left: 50%;
    clip: rect(10px 20px 20px 0);
    margin-left: -10px;

  }

  // border: 1px solid #333;

  & .highlightMenu-arrowClip .highlightMenu-arrow {
    // border: 1px solid #333;

    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    transform: rotate(45deg) scale(.5);
    // box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.25);
    box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.20);
}
box-shadow: 0 1px 4px 0 rgba(0,0,0,0.50);
`

EditorView.propTypes = propTypes
EditorView.defaultProps = defaultProps

export default EditorView