import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  progress: PropTypes.number.isRequired,
  required: PropTypes.number.isRequired,
}

const defaultProps = {
}

const PassphraseHint = ({ progress, required }) => {
  const remaining = required - progress

  let maxProgress = (progress > required) ? required : progress
  const progressLoop = Array.apply(null, { length: maxProgress }).map(Number.call, Number)

  let minRemaining = (progress > required) ? 0 : remaining
  const remainingLoop = Array.apply(null, { length: minRemaining }).map(Number.call, Number)

  const renderHintText = () => {
    if (remaining > 0) {
      return (
        <span> {minRemaining} characters to go</span>
      )
    } else {
      return (
        <span> passphrase is solid</span>
      )
    }
  }

  return (
    <>
      {
        progressLoop.map((val,ndx) => {
          return (
            <Dot key={ndx} filled />
          )
        })
      }
      {
        remainingLoop.map((val, ndx) => {
          return (
            <Dot key={ndx}/>
          )
        })
      }
      {renderHintText()}
    </>
  )
}

const Dot = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 4px;
  background-color: ${ props => props.filled ? '#E62DA0' : '#E4E7EB' }
`

PassphraseHint.propTypes = propTypes
PassphraseHint.defaultProps = defaultProps

export default PassphraseHint