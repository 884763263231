import React from 'react'
import styled from 'styled-components'

const Home = () => {
  return (
    <FullScreen>
      <Flex>
        <div class="element1"></div>
        <div class="element2"></div>
        <div class="element3"></div>
        <div class="pupil"></div>
        <div>&nbsp;</div>
      </Flex>
    </FullScreen>
  )
}

const Flex = styled.div`
  height: 100vh;
  width: 100vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`

const FullScreen = styled.div`
  background-color: #FF0266;

  .element1 {
    position: relative;
    background-color: #FBFBFF;
    height: 320px;
    width: 320px;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  @keyframes pulse {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(50);
    }
  }

  .element2 {
    position: absolute;
    background-color: #FF0266;
    height: 256px;
    width: 256px;
    border-radius: 256px;
    animation-name: pulse2;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  @keyframes pulse2 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      display: none;
    }
  }

  .element3 {
    position: absolute;
    background-color: #FBFBFF;
    height: 128px;
    width: 128px;
    border-radius: 128px;
  }

  .pupil {
    position: absolute;
    background-color: #1A191F;
    height: 64px;
    width: 64px;
    border-radius: 50%;
  }
`

export default Home