import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PopupLink } from 'scenes/DailyUI/components'
import { PassphraseHint } from './components'
import headerBG from './illustration-001.png'

const initialState = {
  userEmail: '',
  userEmailConfirm: '',
  passphrase: '',
  passphraseCount: 0,
  passphraseInputType: 'password',
  passphraseIcon: 'visibility',
  submitted: false,

  errors: [],
  userEmailError: false,
  userEmailConfirmError: false,
  userPassphraseError: false,
}

const Page = () => {
  const emailInput = useRef()
  const passphraseInput = useRef()
  const [ state, setState ] = useState(initialState)

  useEffect(() => {
    if (!state.submitted) {
      emailInput.current.focus()
    }
  },[state.submitted])

  useEffect(() => {
    if (state.errors.length) {
      if (state.errors.length === 1) {
        document.title = '(' + state.errors.length + ') error - ' + document.title
      } else {
        document.title = '(' + state.errors.length + ') errors - ' + document.title
      }
    }
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    // clear old errors
    let newState =  {
      ...state,
      errors: [],
      userEmailError: false,
      userEmailConfirmError: false,
      userPassphraseError: false,
    }

    let haveErrors = false

    // validate email
    if (state.userEmail.length === 0) {
      newState.errors = [ ...newState.errors, 'userEmail']
      newState.userEmailError = true
      haveErrors = true
    }

    // validate email confirmation
    if (state.userEmailConfirm.length === 0) {
      newState.errors = [ ...newState.errors, 'userEmailConfirm' ]
      newState.userEmailConfirmError = true
      haveErrors = true
    }

    // validate passphrase
    if (state.passphrase.length < 16) {
      newState.errors = [ ...newState.errors, 'userPassphraseError' ]
      newState.userPassphraseError = true
      haveErrors = true
    }

    // validate email match
    if (state.userEmail !== state.userEmailConfirm) {
      newState.errors = [ ...newState.errors, 'userEmailConfirm' ]
      newState.userEmailConfirmError = true
      haveErrors = true
    }

    if (haveErrors) {
      // show result
      setState({
        ...state,
        ...newState
      })

      return
    } else {
      // show result
      setState({
        ...state,
        submitted: true
      })
    }
  }

  const handleReset = () => {
    setState(...initialState)
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    // if passphrase
    if (name === 'passphrase') {
      state.passphraseCount = value.length
    }

    setState({
      ...state,
      [ name ]: value
    })
  }

  const handleRevealClick = (event) => {
    if (state.passphraseInputType === 'password') {
      setState({
        ...state,
        passphraseInputType: 'text',
        passphraseIcon: 'visibility_off',
      })
    } else {
      setState({
        ...state,
        passphraseInputType: 'password',
        passphraseIcon: 'visibility',
      })
    }
  }

  return (
    <Layout>
      <Nav>
        <ul>
          <li>
            <Link to="/projects/daily-ui">←</Link>
          </li>
        </ul>
      </Nav>
      <Body>
        <Form>
          <FormHeader />

            {
              state.submitted &&
              <div style={{ width: '480px' }}>
                <h1>Welcome!</h1>
                <h2>Submitting to server</h2>
                <Result>
                  <div><span>email</span> {state.userEmail}</div>
                  <div><span>passphrase</span> {state.passphrase}</div>
                </Result>
                <form noValidate onSubmit={handleReset}>
                  <input type="submit" className="button-primary" name="reset" value="Reset" />
                </form>
              </div>
            }

            {
              !state.submitted &&
              <div style={{ width: '480px'}}>
                <h1>Join our Community</h1>
                <p>
                  Get personalized content, connect with like-minded people,
                  discover the secrets of the universe and turn lead into gold. <PopupLink>learn more</PopupLink>
                </p>
                <div>
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="field">
                      <label htmlFor="userEmail">
                        <span className="field-label">Your email</span>
                      </label>
                      {
                        state.userEmailError &&
                        <span className="field-error">enter your email</span>
                      }
                      <input
                        ref={emailInput}
                        type="email"
                        id="userEmail"
                        name="userEmail"
                        autoCapitalize="none"
                        autoCorrect="off"
                        spellCheck="false"
                        value={state.userEmail}
                        onChange={handleInputChange}
                      />
                    </div>

                      <div className="field">
                        <label htmlFor="userEmailConfirm">
                          <span className="field-label">Confirm your email</span>
                        </label>
                        {
                          state.userEmailConfirmError &&
                        <span className="field-error">confirm your email</span>
                        }
                        <input
                          type="email"
                          id="userEmailConfirm"
                          name="userEmailConfirm"
                          autoCapitalize="none"
                          autoCorrect="off"
                          spellCheck="false"
                          value={state.userEmailConfirm}
                          onChange={handleInputChange}
                        />
                      </div>

                    <div className="field">
                      <label htmlFor="passphrase">
                        <span className="field-label">Passphrase</span>
                        {
                          state.userPassphraseError &&
                          <span className="field-error">enter a 16 character passphrase</span>
                        }
                        <span className="field-hint">
                          <PassphraseHint progress={state.passphraseCount} required={16} />
                        </span>
                      </label>
                      <div className="passphrase">
                        <input
                          ref={passphraseInput}
                          type={state.passphraseInputType}
                          id="passphrase"
                          name="passphrase"
                          autoCapitalize="none"
                          autoCorrect="off"
                          spellCheck="false"
                          value={state.passphrase}
                          onChange={handleInputChange}
                        />
                        <button type="button" onClick={handleRevealClick}>
                          <i className="material-icons">{state.passphraseIcon}</i>
                        </button>
                      </div>
                    </div>
                    <p>
                      By joining you agree to sell your soul to satan.
                      Just kidding, you’re only agreeing to our <PopupLink>Terms of Service</PopupLink>
                    </p>
                    <input type="submit" className="button-primary" name="login" value="Join" />
                  </form>
                </div>
              </div>
            }
        </Form>
      </Body>
    </Layout>
  )
}

const Layout = styled.div`
  --B900: #1F2933;
  --B700: #3E4C59;
  --B600: #52606D;
  --B400: #7B8794;
  --B100: #E4E7EB;
  --P500: #E62DA0;
  --P400: #E65BB2;

  --REGULAR: 400;
  --MEDIUM: 500;
  --SEMIBOLD: 600;
  --BOLD: 700;

  background-color: #407EFF;
  color: white;

  // flex: 1;
  // display: flex;
  // flex-direction: row;

  h1 {
    font-size: 36px;
    line-height: 43px;
    font-weight: 600;
    margin: 32px 0 0 0;
  }

  h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    margin: 24px 0 24px 0;
  }

  p {
    color: var(--B700);
    font-size: 18px;
    line-height: 27px;
    margin: 16px 0 32px 0;
  }

  a, a:visited, a:active {
    color: var(--P500);
    text-decoration: underline;
  }
`

const Result = styled.div`
  margin-bottom: 48px;

  div {
    height: 32px;
    line-height: 32px;
  }

  div span {
    font-weight: var(--MEDIUM);
    color: var(--B600);
    width: 128px;
    display: inline-block;
  }
`

const FormHeader = styled.div`
  background-color: #E4E7EB;
  background-image: url('${headerBG}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 336px;
  min-height: 336px;
  width: 100%;
`

const Nav = styled.nav`
  height: 64px;

  a, a:visited, a:active {
    color: white;
    text-decoration: none;
    font-size: 32px;
    height: 32px;
    width: 32px;
  }

  ul {
    padding: 0;
    margin-left: 24px;
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Form = styled.div`
  color: var(--B900);
  background: white;
  width: 800px;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.05), 0 4px 12px 4px rgba(22,66,158,0.50);
  border-radius: 24px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  form {
  }

  .field {
    margin-bottom: 24px;
  }
  .field-label {
    color: var(--B600);
    font-weight: var(--MEDIUM);
    display: block;
    line-height: 1;
    margin-bottom: 12px;
  }

  .field-error, .field-hint {
    color: var(--P500);
    font-size: 12px;
    font-weight: var(--MEDIUM);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 12px;
    display: block;
  }

   .field-hint {
    color: var(--B600);
  }


  [type=email],
  [type=password],
  [type=text] {
    background: var(--B100);
    font-weight: var(--REGULAR);
    box-shadow: inset 0 1px 3px 0 rgba(50,63,75,0.10);
    width: 100%;
    font-size: 18px;
    height: 48px;
    padding: 0 16px 0 16px;
    box-sizing: border-box;
    appearance: none;
    border-radius: 4px;
    border: 0;
  }
  [type=email]:focus,
  [type=password]:focus,
  [type=submit]:focus,
  [type=tel]:focus,
  [type=text]:focus,
  button:focus,
  select:focus {
    outline: 0;
    box-shadow: 0 0 1px 2px var(--P400);
  }
  input[type=password]::-ms-reveal {
    display: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
  }

  [type=submit],
  button {
    height: 44px;
    line-height: 22px;
    border: 2px solid var(--P500);
  }

  [type=submit]:focus,
  button:focus {
    outline: 0;
    border-sizing: border-box;
    background: #E62DA0;
    border: 2px solid #F5F7FA;
    border-radius: 24px;
  }

  .passphrase {
    position: relative;
  }
  .passphrase button {
    color: var(--B400);
    width: 48px;
    line-height: 48px;
    background-color: transparent;
    border: none;
    appearance: none;
    position: absolute;
    top: 0;
    right: 2px;
    bottom: 2px;
    padding: 8px 0;
    border-radius: 4px;
  }
  .passphrase button:hover {
    i {
      color: var(--B700);
    }
  }

  .button-primary {
    background: var(--P500);
    color: white;
    appearance: none;
    font-size: 18px;
    font-weight: var(--MEDIUM);
    height: 44px;
    line-height: 22px;
    min-width: 133px;
    box-shadow: 0 2px 4px 0 rgba(230,45,160,0.10), 0 2px 4px 0 rgba(31,41,51,0.10);
    border-radius: 24px;
    margin-bottom: 56px;
  }
`

export default Page
