import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  children: PropTypes.string.isRequired,
}

const defaultProps = {
}

const PopupLink = ({ children }) => {
  // const [ debug ] = useState(666)

  const onClickHandler = (event) => {
    event.preventDefault()
    console.log(`clicked ${children}`)
  }

  return (
    <StyledPopupLink href="/#" onClick={onClickHandler}>
      { children }
    </StyledPopupLink>
  )
}

const StyledPopupLink = styled.a`
  position: relative;
`

PopupLink.propTypes = propTypes
PopupLink.defaultProps = defaultProps

export default PopupLink